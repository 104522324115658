<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link
        class="brand-logo"
        :href="platformUrl()"
      >
        <platform-logo />
        <h2 class="brand-text text-primary ml-1">
          {{ platformName() }}
        </h2>
        <small
          v-if="platformVersionName()"
          class="pl-50"
        >
          {{ platformVersionName() }}
        </small>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-text class="mb-2" />

          <div class="text-center">
            <b-spinner
              variant="primary"
              label="Please wait ..."
            />
          </div>

        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
    <div id="hiddenBtn" />
  </div>
</template>

<script>
/* eslint-disable global-require */
import PlatformLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BCardText,
  BImg,
  VBTooltip, BSpinner,
} from 'bootstrap-vue'
import useSession from '@/auth/jwt/useJwt'
// import useSession from '/src/@core/auth/session/useSession'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser, setUserData } from '@/auth/utils'

import {
  catchException, isDemoHost, platformName, platformUrl, platformVersionName, toast,
} from '@core/utils/utils'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BSpinner,
    BRow,
    BCol,
    BLink,
    BCardText,
    BImg,
    PlatformLogo,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      isLoading: false,
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      errors: {
      },
      recaptcha: null,
      isDemo: isDemoHost(),

      // validation rules
      required,
      email,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  beforeMount() {
    this.impersonationLogin()
  },
  mounted() {
  },
  methods: {
    platformUrl,
    platformVersionName,
    platformName,
    impersonationLogin() {
      this.isLoading = true
      const url = new URL(window.location.href)
      const token = url.searchParams.get('token')
      console.log('token', token)
      useSession.csrf().then(() => {
        useSession.impersonate(token)
          .then(response => {
            this.postLoginProcedures(response)
          })
          .catch(error => {
            catchException(error)
            // toast('danger', error?.response?.data?.message || error?.message || 'Something went wrong!'/* , errorText */)
            window.location.href = platformUrl()
          })
      })
    },
    postLoginProcedures(response) {
      const responseData = response.data
      const itemData = responseData.user
      const storeData = responseData.store
      const userRole = 'admin' // itemData.role
      useSession.setToken(responseData.access_token)
      // useSession.setRefreshToken(response.data.refreshToken)
      setUserData(itemData)
      this.$store.commit('storeConfig/UPDATE_STORE_DATA', storeData)

      this.$ability.update(itemData.ability)

      // ? This is just for demo purpose as well.
      // ? Because we are showing eCommerce app's cart items count in navbar
      // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', itemData.eCommerceCartItemsCount)

      if (storeData.layout_config) {
        this.$store.commit('appConfig/UPDATE_SKIN', storeData.layout_config.skin)
        this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', Boolean(storeData.layout_config.menu_collapsed))
      }

      let returnToRoute = this.$router.currentRoute.query.return
      if (returnToRoute === '/login') {
        returnToRoute = null
      }

      // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
      this.$router.replace(returnToRoute || getHomeRouteForLoggedInUser(userRole))
        .then(() => {
          if (!this.isDemo) {
            toast(
              'success',
              this.$t('auth.login.success_login.title', { name: itemData.fullName || itemData.username }),
              this.$t('auth.login.success_login.text'),
            )
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
